<template>
	<div>
        <v-row>
			<v-col>
                <s-crud
                    title="Configuracion de Presentacion"
                    :config="config"
                    :filter="filter"
                    add
                    edit
                    remove
                    @save="save($event)"
                    ref="gridPretConf"
                    @rowSelected="rowSelected($event)" 
                    
                >
                <template v-slot:filter>
                    <v-container>
                        <v-row>
                            <v-col cols="8" lg="4"  md="4" sm="4">
                                    <s-select-definition
                                        label="Tipo Presentación"
                                        :def="1349"
                                        clearable
                                        v-model="filter.TypePresentationPacking"
                                    />
                            </v-col>				            			
                            <v-col cols="4" lg="2" md="3" sm="2">
                                    <s-select-definition
                                        label="Presentación"              
                                        :def="1274"
                                        clearable                                                  
                                        v-model="filter.TypeBoxPresentation"
                                    />
                            </v-col>
                        </v-row>
                    </v-container>
			    </template>
                <template scope="props">
                    <v-card class="d-flex justify-center mb-6 pl-20 pr-20">                       
                        <v-container>
                            <v-row style="mt-3" justify="center">
                                <!--<v-col cols="12" lg="4" class="pb-0">
                                    <s-supplier
                                        label="Cliente"
                                        return-object
                                        v-model="props.item.ClnCode"
                                        :text = "props.item.CardName"
                                    ></s-supplier>
                                </v-col>-->
                                <v-col cols="4" lg="2" md="3" sm="12">
                                    <s-select-definition
                                        label="Presentación"           
                                        :def="1274"                                                                   
                                        v-model="props.item.TypeBoxPresentation"
                                    />
                                </v-col>
                                <v-col cols="8" lg="4" class="pb-0">
                                    <s-select-definition
                                        label="Tipo Presentación"
                                        :def="1349"
                                        v-model="props.item.TypePresentationPacking"
                                    />
                                </v-col>  
                                <v-col cols="4" md="3" lg="2">
                                    <s-select-definition
                                        :def="1172"
                                        label="Tipo Cultivo"
                                        v-model="props.item.TypeCrop"
                                    />
                                </v-col>                          
                                <v-col cols="6" md="3" lg="2">
                                    <s-select-definition
                                        :def="1173"
                                        label="Cultivo"
                                        v-model="props.item.TypeCultive"
                                    />
                                </v-col>
                               <!-- <v-col>
                                    <s-select-definition
                                        :def="1266"
                                        label="Calibres"
                                        v-model="props.item.TypeCaliber">

                                    </s-select-definition>
                                </v-col>-->
                            </v-row>
                            <v-row style="mt-3" justify="center">
                               <!-- <v-col cols="4">
                                    <s-select-definition
                                        label="Marca de caja"
                                        v-model="props.item.TypeBrandBox"
                                        :def="1427">
                                        
                                    </s-select-definition>
                                </v-col> -->

                              

                                <v-col cols="4" md="3" lg="2">
                                    <s-select-variety
                                        :cultiveID="props.item.TypeCultive"
                                        label="Variedad"
                                        full
                                        v-model="props.item.VrtID"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>                    
                    </v-card>
                </template>
                </s-crud>
            </v-col>
        </v-row>
    </div>
</template>

<script>
	import SSelectDefinition        from "@/components/Utils/SSelectDefinition.vue";
	import _sPrfPresentationConfig  from "@/services/FreshProduction/PrfPresentationConfigService";
	import SSelectVariety           from "@/components/HarvestSchedule/SSelectVariety";
	import SSupplier                from "@/components/Sale/SselectClientSap";

    export default {
		components: {
			SSelectDefinition,
			SSelectVariety,
			SSupplier,
		},
        data() {
			return {
				//client:             null,
				filter: {
					
				},
				config: {
                    title: "Presentacion",
					model: {
						PrcID: "ID",					
						
					},
					service: _sPrfPresentationConfig,
					headers: [
						{ text: "ID",                   value: "PrcID",                         width: 50,  },
                        //{ text: "Cliente",              value: "CardName",                      width: 150, },
						{ text: "Tipo Presentación",    value: "TypePresentationPackingName",   width: 50,  },
						{ text: "Presentación",	        value: "TypeBoxPresentationName",       width: 50,  },
						//{ text: "Calibre",              value: "TypeCaliberName",               width: 100, },
                        { text: "Cultivo",              value: "TypeCultiveName",               width: 100, },
                        //{ text: "Marca de Caja",        value: "TypeBrandBoxName",              width: 100, },
                        { text: "Tipo Cultivo",         value: "TypeCropName",                  width: 100, },
                        { text: "Variedad",             value: "VrtDescription",                width: 100, },
					],
				},				
				TypeCultive:    null,
				//TypeCaliber:    null,
				//TypeBrandBox:   null,
				TypeCrop:       null,
				VrtID:          null,
				itemView:   {},
				TypePresentationPacking: 1,                
			};
		},
        watch: {
			
        },
        methods: {
			rowSelected(item) {
				if (item.length > 0) {
					if (item !== undefined) {
						this.itemView = item[0];
						console.log("item", this.itemView);
					}
				}
			},
            save(item) {				
                console.log("Cliente", item.ClnCode);
                /*if(item.ClnCode == undefined){
                    this.$fun.alert("Ingrese cliente", "warning");
					return;
                }
                item.CardName = item.ClnCode.CardName;
                item.ClnCode = item.ClnCode.CardCode;*/
				console.log("item save", item);
                item.save();
			},
        },	
	};
</script>